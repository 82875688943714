import React from 'react';
import { cx } from 'emotion';
import { EditorialMarkdown } from '@calico/calico-ui-editorial';

import TutorialContent from '../content/Tutorial.md';

const TutorialPage = ({ className, style }) => {
  return (
    <EditorialMarkdown
      className={cx('article content-width', className)}
      content={TutorialContent}
      style={style}
    />
  );
}

export default TutorialPage;
