import {
  BrowserRouter as Router,
  Route,
  Switch,
} from 'react-router-dom';
import React from 'react';
import DocumentTitle from 'react-document-title';
import { Colors } from '@calico/calico-ui-kit';
import {
  AuthorList,
  CalicoResearchFooter,
  CalicoResearchHeader,
  Citation,
  Navigation,
  ShareButtons,
  Title,
} from '@calico/calico-ui-editorial';

import Authors from './content/Authors';
import CxgPage from './pages/CxgPage';
import DownloadsPage from './pages/DownloadsPage';
import HomePage from './pages/HomePage';
import SoftwarePage from './pages/SoftwarePage';
import TutorialPage from './pages/TutorialPage';

const CITATION = '';
const DOI = 'https://doi.org/10.1016/j.celrep.2023.112902';
const CITATION_TITLE = 'Individual cell types in C. elegans age differently and activate distinct cell-protective responses';
const RESEARCH_TITLE = 'The complete cell atlas of C. elegans aging';
const WEBSITE_URL = 'http://c.elegans.aging.atlas.research.calicolabs.com';

class App extends React.Component {
  state = {
    calicoResearchHeaderBottom: 0,
    scrollTop: 0,
    titleBottom: 0,
  };

  updateScroll = (event: Object) => {
    this.setState({ scrollTop: event.target.scrollTop });
  }

  render () {
    const { calicoResearchHeaderBottom, scrollTop, titleBottom } = this.state;
    return (
      <DocumentTitle title={`${RESEARCH_TITLE} | Calico research`}>
        <div>
          <Router basename={process.env.PUBLIC_URL}>
            <div
              className="relative app flex-column"
              id="app"
              onScroll={this.updateScroll}
              style={{
                height: '100vh',
                overflowX: 'hidden',
                overflowY: 'scroll',
              }}
            >
              <CalicoResearchHeader
                onMeasureElement={rect => this.setState({ calicoResearchHeaderBottom: rect.bounds.bottom })}
                subheader={RESEARCH_TITLE}
                showSubheader={scrollTop > titleBottom - calicoResearchHeaderBottom}
              />
              <Title
                onMeasureElement={rect => this.setState({ titleBottom: rect.bounds.bottom })}
                scrollTop={scrollTop}
                title={RESEARCH_TITLE}
              />
              <Navigation
                className="content-width"
                items={[
                  {
                    name: 'Home',
                    path: '/'
                  },
                  {
                    name: 'Tutorial',
                    path: '/tutorial'
                  },
                  {
                    name: 'User Interface',
                    path: '/embeddings'
                  },
                  {
                    name: 'Downloads',
                    path: '/data'
                  },
                ]}
                style={{
                  backgroundColor: scrollTop > titleBottom && Colors['grey-xxxl'],
                  top: calicoResearchHeaderBottom,
                }}
              />

              <Route
                exact
                path="/"
                render={() => (
                  <section className="content-width font-size-small smooth">
                    <Citation
                      citation={CITATION}
                      className="margin-bottom-2 bold"
                      uri={DOI}
                      title={CITATION_TITLE}
                      showCopyUri
                      showCopyCitation
                      style={{ lineHeight: 1.35 }}
                    />
                    <AuthorList
                      authors={Authors}
                      showContact
                      contactSubject={RESEARCH_TITLE}
                    />
                  </section>
                )}
              />
              {
              /*
               * Temporarily removed for p-counsel review.
               *
              <Route
                exact
                path="/"
                render={() => (
                  <ShareButtons
                    className="home-share-buttons"
                    style={{ top: titleBottom }}
                    title={RESEARCH_TITLE}
                    url={WEBSITE_URL}
                  />
                )}
              />
               *
               */
              }

              <section className="flex-1" style={{ minHeight: '150vh' }}>
                <Switch>
                  <Route exact path="/" component={HomePage} />
                  <Route exact path="/tutorial" component={TutorialPage} />
                  <Route exact path="/embeddings" component={CxgPage} />
                  <Route exact path="/data" component={DownloadsPage} />
                  <Route exact path="/software" component={SoftwarePage} />
                </Switch>
                <CalicoResearchFooter>
                  {
                  /*
                   * Temporarily removed for p-counsel review.
                   *
                  <ShareButtons
                    title={RESEARCH_TITLE}
                    url={WEBSITE_URL}
                  />
                   *
                   */
                  }
                </CalicoResearchFooter>
              </section>
            </div>
          </Router>
        </div>
      </DocumentTitle>
    );
  }
}

export default App;
