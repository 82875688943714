import React from 'react';
import { Bullets } from '@calico/calico-ui-editorial';
import { LinkExternal } from '@calico/calico-ui-kit';

const BASE_URL = 'https://storage.googleapis.com/worm_public';
const META_DES = "Cell type annotations ('annotate_name'), ages ('timepoint') are available as obs annotations."

export default [
  {
    format: 'h5ad',
    name: 'single cell C. elegans aging count data',
    size: '3.9 G',
    src: `${BASE_URL}/ad_worm_aging.h5ad`,
    title: 'single cell C. elegans aging count data',
    content: (
      <div className="max-line-length">
        <p>Data are formatted following the AnnData conventions.
        Low quality cells/genes has been removed following the procedures described in the manuscript.
        CellBender is applied for ambient RNA removal. Solo is applied for doublet removel.
        scVI is applied for denoising and cell representation. scVI representations are in anndata.obsm['scvi'], 
        scVI denoised expression matrix is in anndata.layers['denoised'].
        </p>
        <p>{META_DES}</p>
        <p>Includes the following files:</p>
        <Bullets
          bullets={[
            {
              title: <strong>ad_worm_aging.h5ad</strong>,
              content: 'Single cell RNA count data for aging C. elegans population',
            }
          ]}
          className="grey"
        />
      </div>
    ),
  },
];
