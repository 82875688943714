export default [
  {
    nameFirst: 'Antoine E.',
    nameLast: 'Roux',
    email: 'antoine@calicolabs.com',
    affiliations: [{ organization: 'Calico' }],
    corresponding: false,
  },
  {
    nameFirst: 'Han',
    nameLast: 'Yuan',
    affiliations: [{ organization: 'Calico' }],
    corresponding: false,    
  },
  {
    nameFirst: 'Katie',
    nameLast: 'Podshivalova',
    affiliations: [{ organization: 'Calico' }],
    corresponding: false,    
  },  
  {
    nameFirst: 'David G.',
    nameLast: 'Hendrickson',
    affiliations: [{ organization: 'Calico' }],
    corresponding: false,    
  },  
  {
    nameFirst: 'Rex',
    nameLast: 'Kerr',
    affiliations: [{ organization: 'Calico' }],
    corresponding: false,
  },
  {
    nameFirst: 'Cynthia',
    nameLast: 'Kenyon',
    affiliations: [{ organization: 'Calico' }],
    corresponding: false,
  },
  {
    nameFirst: 'David R.',
    nameLast: 'Kelley',
    email: 'drk@calicolabs.com',
    affiliations: [{ organization: 'Calico' }],
    corresponding: false,
  },
];
